import request from '@/utils/request'

const payment_ticketApi = {
  payment_ticket_list: '/payment_ticket/',
  payment_ticket_create: '/payment_ticket/',
  payment_ticket_record_list: '/payment_ticket/record/',
  payment_ticket_partial_update: '/payment_ticket/',
  payment_ticket_delete: '/payment_ticket/'
}

/**
 * 列表
 */
export function payment_ticket_list (parameter) {
  return request({
    url: payment_ticketApi.payment_ticket_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 创建
 */
export function payment_ticket_create (parameter) {
  return request({
    url: payment_ticketApi.payment_ticket_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 列表
 */
export function payment_ticket_record_list (parameter) {
  return request({
    url: payment_ticketApi.payment_ticket_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function payment_ticket_partial_update (parameter, payment_ticket_id) {
  return request({
    url: payment_ticketApi.payment_ticket_partial_update + payment_ticket_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function payment_ticket_delete (payment_ticket_id) {
  return request({
    url: payment_ticketApi.payment_ticket_delete + payment_ticket_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
