var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"资产名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bill_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['bill_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'category',
                {
                  rules: [
                    { required: true, message: '必填项，请填写信息' },
                  ],
                },
              ]),expression:"[\n                'category',\n                {\n                  rules: [\n                    { required: true, message: '必填项，请填写信息' },\n                  ],\n                },\n              ]"}],attrs:{"allow-clear":""},on:{"change":_vm.categoryChange}},_vm._l((this.$Dictionaries.PaymentTicketCategory),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }