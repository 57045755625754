<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BillStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>-->

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要支付么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleUpdate(record)"
            :disabled="paying"
            v-if="record.status === 1"
          >
            <a-spin style="display: inline-block" :spinning="paying && record.id === paymdl.id">
              <a>支付</a>
            </a-spin>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <create-pay-form
      ref="createPayModal"
      v-if="payvisible"
      :visible="payvisible"
      :loading="payconfirmLoading"
      :model="paymdl"
      @cancel="handlePayCancel"
      @ok="handlePayOk"
    />
    <payment-form
      ref="paymentModal"
      :visible="paymentvisible"
      :loading="paymentConfirmLoading"
      :model="paymentMdl"
      @cancel="handlePaymentCancel"
      @ok="handlePaymentOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  property_bill_list,
  property_bill_create,
  property_bill_delete,
  property_bill_payment
} from '@/api/property_bill'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import CreatePayForm from '../modules/CreatePayForm'
import PaymentForm from '../modules/PaymentForm'
import { payment_ticket_create } from '@/api/payment_ticket'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    CreatePayForm,
    PaymentForm
  },
  data () {
    return {
      paying: false,
      paymentConfirmLoading: false,
      paymentvisible: false,
      paymentMdl: {},
      payvisible: false,
      paymdl: {},
      payconfirmLoading: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '实际付款时间',
          dataIndex: 'actual_payment_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text,
          ellipsis: true
        },
        {
          title: '最后付款日期',
          dataIndex: 'payment_date',
          ellipsis: true
        },
        {
          title: '开始日期',
          dataIndex: 'start_date',
          ellipsis: true
        },
        {
          title: '结束日期',
          dataIndex: 'end_date',
          ellipsis: true
        },
        {
          title: '需付款(元)',
          dataIndex: 'total_payment',
          align: 'center',
          scopedSlots: { customRender: 'money_render' }
        },
        // {
        //   title: '实际付款(元)',
        //   scopedSlots: { customRender: 'money_render' },
        //   dataIndex: 'actual_payment',
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true
        // },
        {
          title: '账户',
          dataIndex: 'property_owner',
          customRender: (text) => '*' + this.$Dictionaries.property_owner[text].substr(this.$Dictionaries.property_owner[text].length - 4, this.$Dictionaries.property_owner[text]),
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.BillStatus[text],
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return property_bill_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    handlePaymentCancel () {
      this.paymentvisible = false
      const form = this.$refs.paymentModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePaymentOk () {
      const form = this.$refs.paymentModal.form
      this.paymentConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          property_bill_payment(values).then(res => {
            this.paymentvisible = false
            this.paymentConfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          })
        } else {
          this.paymentConfirmLoading = false
        }
      })
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleUpdate (record) {
      console.log(record)
      // this.current_bill_id = record.id
      // this.payvisible = true
      if (this.paying) {
        this.$message.warning('支付请求提交中,请勿重复提交')
        return
      }
      this.paymdl = record
      this.paying = true
      property_bill_payment({ bill_id: record.id, category: 1 }).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh(true)
        }
      }).finally(() => {
        this.paying = false
        this.paymdl = {}
      })
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayOk () {
      const form = this.$refs.createPayModal.form
      this.payconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.amount = Math.round(values.amount * 100)
          const param = Object.assign({}, values, { corporation_id: this.$Dictionaries.corporation_id, bill_id: this.current_bill_id, category: 1 })
          // 新增
          payment_ticket_create(param).then(res => {
            this.payvisible = false
            this.payconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
        } else {
          this.payconfirmLoading = false
        }
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      property_bill_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          property_bill_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
